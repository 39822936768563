@font-face {
  font-family: 'Couplet CF';
  src: url('CoupletCF-Regular.eot') format('eot'),
    url('CoupletCF-Regular.woff') format('woff'),
    url('CoupletCF-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: normal;
}

@font-face {
  font-family: 'Couplet CF';
  src: url('CoupletCF-Medium.eot') format('eot'),
    url('CoupletCF-Medium.woff') format('woff'),
    url('CoupletCF-Medium.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
}

@font-face {
  font-family: 'Couplet CF';
  src: url('CoupletCF-DemiBold.eot') format('eot'),
    url('CoupletCF-DemiBold.woff') format('woff'),
    url('CoupletCF-DemiBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
}

@font-face {
  font-family: 'Couplet CF';
  src: url('CoupletCF-ExtraBold.eot') format('eot'),
    url('CoupletCF-ExtraBold.woff') format('woff'),
    url('CoupletCF-ExtraBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 800;
}

@font-face {
  font-family: 'Couplet CF';
  src: url('CoupletCF-Heavy.eot') format('eot'),
    url('CoupletCF-Heavy.woff') format('woff'),
    url('CoupletCF-Heavy.woff2') format('woff2');
    font-style: normal;
    font-weight: 900;
}

@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400 700;
  src: url('Figtree-variable.woff2') format("woff2");
}
